@media screen and (max-width: 400px) {
 
  #about,
  #services,
  #portfolio {
    width: 110%;
  }
  #features {
    padding: 20px;
    width: 111%;
  }
  #contact,
  #footer {
    width: 111%;
  }

 
}
